import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/base.scss'
import 'element-ui/lib/theme-chalk/index.css'
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import {
  Pagination, Carousel, CarouselItem, DropdownMenu,
  DropdownItem,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Row,
  Col,
} from 'element-ui'
import visibleChange from '@/directive'
[
  Pagination,
  Carousel,
  CarouselItem,
  DropdownMenu,
  DropdownItem,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Row,
  Col,
].forEach(c => {
  Vue.component(c.name, c)
})

Vue.use(visibleChange)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
