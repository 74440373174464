


export default {
  install (Vue: any) {
    let handleScrollFn: any = null
    Vue.directive('visibleChange', {
      inserted (el: HTMLElement, { value: { callback, halfCallback } }: any) {
        let inFlag = false
        let outFlag = false
        handleScrollFn = () => {
          const windowHeight = window.innerHeight // 浏览器窗口高度
          const { top, bottom } = el.getBoundingClientRect()
          if (top > windowHeight) {
            // 未滚入到视口
          } else if (top > 0 && top < windowHeight) {
            
            // 滚入视口
            // if (!inFlag) {
              callback(true)
              inFlag = true
              if (top < windowHeight * (2 / 3)) {
                halfCallback && halfCallback(true)
              }
            // } 
          } else if (bottom < 0) {
            // 滚出视口
            if (!outFlag) {
              callback(false)
              outFlag = true
            }
          }
        }
        window.addEventListener('scroll', handleScrollFn)
      },
      unbind () {
        window.removeEventListener('scroll', handleScrollFn)
      }
    })
  }
}