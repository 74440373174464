import Vue from 'vue'
import VueRouter, { Route, RouteConfig } from 'vue-router'
import { Position } from 'vue-router/types/router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    name: 'home',
    path: '/home',
    component: () => import('../views/Home.vue')
  },
  {
    name: 'news',
    path: '/news',
    component: () => import('../views/pages/NewsPage.vue')
  },
  {
    name: 'DetailsList',
    path: '/DetailsList/:id',
    component: () => import('../views/pages/DetailsList.vue')
  },
  {
    name: 'Solution',
    path: '/Solution/:id',
    component: () => import('../views/pages/Solution.vue')
  },
  {
    name: 'aboutUs',
    path: '/aboutUs/:id',
    component: () => import('../views/pages/aboutUs.vue')
  },
  {
    name: 'aboutUsList',
    path: '/aboutUsList/:id',
    component: () => import('../views/pages/aboutUsList.vue')
  },
  {
    name: 'contactUs',
    path: '/contactUs/:id',
    component: () => import('../views/pages/contactUs.vue')
  },
  {
    name: 'news',
    path: '/news/:trendsId',
    component: () => import('../views/pages/NewsDetail.vue')
  },
  {
    name: 'new',
    path: '/new/:parentId',
    component: () => import('../views/pages/NewList.vue')
  },
  {
    path: '*',
    redirect: '/home'
  },
]

// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return (routerPush.call(this, location) as any).catch((err: any) => err)
}


const router = new VueRouter({
  mode: 'history',
  scrollBehavior: (to: Route,
    from: Route,
    savedPosition: Position | void) => ({ y: 0 }) as any,
  base: process.env.BASE_URL,
  routes
})

export default router
